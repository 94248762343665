import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessHasWhoAsk,
  checkSelectedBusinessRpaMode
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoTooltip from "nlib/ui/AutoTooltip";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";
import Transactions from "utils/Transactions";
import TransactionsStatuses from "nlib/pages/TransactionsPage/TransactionsStatuses";
import Utils from "utils/Utils";
import moment from "moment";

const { STATUSES: { NEED_REACTION, EXPORTED } } = DataConstants;

const StatusIcon = ({ status, exportedAt, askClientInfo }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const { businessOrganization } = useSelector(getActiveOrganization);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const selectedBusinessHasWhoAsk = useSelector(checkSelectedBusinessHasWhoAsk);

  const { labelLangId, iconComponent: Icon } = TransactionsStatuses.getStatusData([
    status,
    businessOrganization,
    rpaMode && !selectedBusinessHasWhoAsk,
    businessUser
  ]);

  const { followUp, askedAt, askedBy = {}, askedUser = {} } = askClientInfo || {};

  const askedUserName = askedUser.fullName || askedUser.email;

  if (status === NEED_REACTION && (askedUserName || !rpaMode)) {
    const askedAtTimeText = askedAt && moment.duration(moment.utc(askedAt).diff(moment.utc())).humanize(true);

    const accountantName = (askedBy && (askedBy.fullName || askedBy.email)) || "";

    const userName = askedUser.fullName || askedUser.email;

    const [
      userAskedByDescriptionA,
      userAskedByDescriptionB,
      userAskedByDescriptionC
    ] = messages.userAskedByDescription;

    const userAskedByDescription = [
      Utils.replaceTextVars(userAskedByDescriptionA, { userName }),
      Utils.replaceTextVars(userAskedByDescriptionB, { askedAt: askedAtTimeText }),
      accountantName ? Utils.replaceTextVars(userAskedByDescriptionC, { accountantName }) : "",
      `(${(followUp ? uiTexts.automaticReminder : uiTexts.directRequest).toLowerCase()})`
    ].filter(Boolean).join(" ");

    const subtitle = userName
      ? userAskedByDescription
      : uiTexts.anyBusinessUserCanReply;

    return (
      <div
        className={Css.statusIcon}
        data-tooltip={subtitle}
        data-status={status}>
        <Icon />
      </div>
    );
  }

  if (status === EXPORTED && exportedAt) {
    const exportedAtTimeText = exportedAt && moment.duration(moment.utc(exportedAt).diff(moment.utc())).humanize(true);

    const subtitle = `${uiTexts[labelLangId]}, ${exportedAtTimeText}`;

    return (
      <div
        className={Css.statusIcon}
        data-tooltip={subtitle}
        data-status={status}>
        <Icon />
      </div>
    );
  }

  return (
    <div
      className={Css.statusIcon}
      data-tooltip={uiTexts[labelLangId]}
      data-status={status}>
      <Icon />
    </div>
  );
};

const TransactionCell = ({ audit, transaction }) => {
  const { account, timestamp, status, lastExportErrors, exportedAt, askClientInfo } = transaction;

  const { uiTexts, errors } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const timestampFormatted = timestamp
    ? moment.utc(timestamp).format(Constants.DATETIME_FORMATS.DATE_TEXT)
    : Constants.EMPTY_PLACEHOLDER;

  const advancedType = Transactions.getTransactionAdvancedType(transaction);

  const advancedTypeText = uiTexts[advancedType] || advancedType;

  const toReviewStatus = status === DataConstants.STATUSES.TO_REVIEW;

  return (
    <div className={Css.transactionCell}>
      <Badge
        data-type={advancedType}
        title={advancedTypeText}
        value={advancedTypeText}
        className={Css.badge} />

      <div className={Css.dateTime}>
        <div className={Css.date} title={timestampFormatted}>{timestampFormatted}</div>
        {!audit && !businessUser && (
          <StatusIcon
            status={status}
            exportedAt={exportedAt}
            askClientInfo={askClientInfo} />
        )}
      </div>

      <div className={Css.account}>
        {!businessUser && (audit || toReviewStatus) && Array.isArray(lastExportErrors) && !!lastExportErrors.length
          ? (
            <AutoTooltip className={Css.lastExportErrors}>
              <Icons.Warning weight="bold" />
              <span>{`${errors.exportDocumentError}: ${lastExportErrors.join("; ")}`}</span>
            </AutoTooltip>
          )
          : (account && (
            <AutoTooltip active={!!account.name}>
              <span>{account.name}</span>
            </AutoTooltip>
          ))}
      </div>
    </div>
  );
};

export default React.memo(TransactionCell);
