import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AttachedDocumentActions from "nlib/common/AttachedDocumentActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DocumentPreview from "nlib/common/DocumentPreview";
import DragAndDropArea from "nlib/ui/DragAndDropArea";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useState } from "react";
import TransactionsActions from "actions/TransactionsActions";
import useAttachDocument from "hooks/useAttachDocument";

const ACCEPT_FILE_TYPES = Object
  .values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []);

const {
  DOCUMENT_RECOGNISE_STATUSES,
  STATUSES: { TO_REVIEW, TO_REPORT, EXPORTED },
  ADVANCED_TRANSACTION_TYPES: { TRANSFER }
} = DataConstants;

const Document = (props) => {
  const { disabled, advancedType, transaction, onDocumentAttach } = props;

  const dispatch = useDispatch();

  const {
    id: transactionId,
    type,
    status,
    usesItems,
    documentId,
    documentPreview,
    documentRecogniseStatus
  } = transaction;

  const { uiTexts } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const attachDocument = useAttachDocument({ id: transactionId, documentId, type });

  const [fetching, setFetching] = useState(false);

  const disabledInput = (
    (businessUser ? status === TO_REVIEW : (status === TO_REPORT || ((status === EXPORTED) && !quickBooksBusiness)))
  ) || advancedType === TRANSFER;

  const handleDropFiles = useCallback(async([file]) => {
    if (!file) return;

    setFetching(true);
    dispatch(TransactionsActions.lockTransaction(transactionId));

    const result = await attachDocument(file);

    if (result && onDocumentAttach) onDocumentAttach(transactionId, result.id);

    setFetching(false);
    dispatch(TransactionsActions.unlockTransaction(transactionId));
  }, [attachDocument, dispatch, onDocumentAttach, transactionId]);

  if (usesItems) return null;

  return (
    <div className={Css.document}>
      {documentId
        ? (
          <>
            <DocumentPreview
              className={Css.preview}
              error={documentRecogniseStatus === DOCUMENT_RECOGNISE_STATUSES.ERROR}
              transactionId={transactionId}
              documentPreview={documentPreview} />
            <AttachedDocumentActions
              className={Css.attachedDocumentActions}
              disabled={disabled || fetching}
              transaction={transaction} />
          </>
        )
        : (
          <DragAndDropArea
            multiple={false}
            disabled={disabled || fetching || disabledInput}
            title={(!disabledInput && !documentId) ? uiTexts.addDocument : ""}
            className={Css.attachment}
            accept={ACCEPT_FILE_TYPES}
            onDrop={handleDropFiles}>
            {({ accepted }) => {
              const loadingState = fetching || !!accepted.length;

              return (
                <div className={Css.content}>
                  {loadingState ? <Preloader className={Css.preloader} /> : <Icons.FilePlus />}
                  <span>{loadingState ? `${uiTexts.uploading}...` : uiTexts.addDocument}</span>
                </div>
              );
            }}
          </DragAndDropArea>
        )}
    </div>
  );
};

export default React.memo(Document);
