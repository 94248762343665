import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ConfidenceAppend from "nlib/common/ConfidenceAppend";
import DataConstants from "const/DataConstants";
import React, { useState } from "react";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import VendorValidation from "nlib/common/VendorValidation";
import classNames from "classnames";

const {
  ADVANCED_TRANSACTION_TYPES: { TRANSFER }
} = DataConstants;

const SelectCategory = (props) => {
  const {
    type,
    audit,
    disabled,
    className,
    transaction,
    vendorId,
    category,
    placeholder,
    advancedType,
    validateCategory,
    showVendorValidation,
    onChange,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const [inputOpened, setInputOpened] = useState(false);

  const [validationOpened, setValidationOpened] = useState(false);

  return (
    <div className={classNames(Css.category, className)}>
      <VendorValidation
        idProp="code"
        nameProp="displayName"
        listName="categories"
        name="category"
        skip={!showVendorValidation || disabled}
        vendorId={vendorId}
        opened={validationOpened && !inputOpened}
        entityValue={category}
        confidence={audit ? null : category?.confidence}
        setOpened={setValidationOpened}
        updateTransaction={onChange}>
        {({ iconComponent, showWarning, showCriticalWarning }) => {
          return (
            <ConfidenceAppend
              icon={iconComponent}
              confidence={audit ? null : category?.confidence}>
              <SelectCategoryInput
                entityPaymentType={type}
                opened={inputOpened}
                setOpened={setInputOpened}
                disabled={disabled}
                value={category}
                valid={!!category?.code}
                placeholder={placeholder || (!category?.displayName && disabled ? uiTexts.noCategory : undefined)}
                invalid={(validateCategory && !category?.code) || showCriticalWarning}
                warning={showWarning}
                border={(showWarning || showCriticalWarning) && "warning"}
                assetAccountsOnly={advancedType === TRANSFER}
                onChange={onChange}
                {...restProps} />
            </ConfidenceAppend>
          );
        }}
      </VendorValidation>
    </div>
  );
};

export default React.memo(SelectCategory);
