import Css from "./style.module.scss";

import DataConstants from "const/DataConstants";
import React from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const { TRANSACTION_TYPES: { WITHDRAW } } = DataConstants;

const TransactionAmount = ({ type, amount, currency, className }) => {
  const withdrawTransaction = type === WITHDRAW;

  const content = Utils.toMoneyString(amount || 0, currency);

  return (
    <div
      className={classNames(
        Css.transactionAmount,
        type && (withdrawTransaction ? Css.negative : Css.positive),
        className
      )}>
      {content}
    </div>
  );
};

export default React.memo(TransactionAmount);
