import Css from "./style.module.scss";

import { checkIsBusinessUser } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import {
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "nlib/ui/Select";
import SelectClassInput from "nlib/common/SelectClassInput";
import SelectLocationInput from "nlib/common/SelectLocationInput";
import SelectProjectInput from "nlib/common/SelectProjectInput";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";

const { STATUSES: { TO_REVIEW } } = DataConstants;

const CLASS_TYPE = 0;

const LOCATION_TYPE = 1;

const PROJECT_TYPE = 2;

const TAX_RATE_TYPE = 3;

const ExtraDataInput = ({ disabled, transactionState, transaction = {}, onChange }) => {
  const { class: classValue = {}, location = {}, project = {}, taxRate = {} } = transactionState;

  const { id: classId, name: className } = classValue;

  const { id: locationId, name: locationName } = location;

  const { id: projectId, name: projectName } = project;

  const { id: transactionId, status } = transaction;

  const { uiTexts } = useSelector(getTextsData);

  const { countryCode } = useSelector(getActiveOrganization);

  const classes = useSelector(getSelectedBusinessClasses);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const usCountry = countryCode === Countries.US;

  const {
    settings: {
      allowClientClassSelection,
      allowClientLocationSelection,
      allowClientProjectSelection,
      allowClientTaxRateSelection
    } = {}
  } = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  const [opened, setOpened] = useState(false);

  const disabledInput = disabled || status === TO_REVIEW;

  const selectOptions = useMemo(() => {
    return [
      (!businessUser || allowClientClassSelection) && classes.length
        && { value: CLASS_TYPE, label: uiTexts.class },
      (!businessUser || allowClientLocationSelection) && locations.length
        && { value: LOCATION_TYPE, label: uiTexts.location },
      (!businessUser || allowClientProjectSelection) && projects.length
        && {
          value: PROJECT_TYPE,
          label: [
            projects.some(({ customer }) => !customer) && uiTexts.project,
            projects.some(({ customer }) => customer) && uiTexts.customer
          ].filter(Boolean).join("/")
        },
      (!businessUser || allowClientTaxRateSelection) && !usCountry && taxRates.length
        && { value: TAX_RATE_TYPE, label: uiTexts.taxRate }
    ].filter(Boolean);
  }, [
    uiTexts,
    usCountry,
    businessUser,
    allowClientClassSelection,
    allowClientLocationSelection,
    allowClientProjectSelection,
    allowClientTaxRateSelection,
    projects,
    classes.length,
    locations.length,
    taxRates.length
  ]);

  const [type, setType] = useState(CLASS_TYPE);

  const handleClassChange = useCallback((newValue) => {
    onChange(transactionId, { class: newValue });
  }, [transactionId, onChange]);

  const handleLocationChange = useCallback((newValue) => {
    onChange(transactionId, { location: newValue });
  }, [transactionId, onChange]);

  const handleProjectChange = useCallback((newValue) => {
    onChange(transactionId, { project: newValue });
  }, [transactionId, onChange]);

  const handleTaxRateChange = useCallback((newValue) => {
    onChange(transactionId, { taxRate: newValue });
  }, [transactionId, onChange]);

  const handleSelectClick = useCallback(() => {
    setOpened(false);
  }, []);

  const typeSelector = useMemo(() => {
    if (disabledInput || !(selectOptions.length > 1)) {
      return null;
    }

    return (
      <Select
        className={Css.typeSelect}
        dropDownClassName={Css.typeSelectDropdownClassName}
        value={type}
        options={selectOptions}
        onChange={setType}
        onClick={handleSelectClick} />
    );
  }, [disabledInput, type, selectOptions, handleSelectClick]);

  useEffect(() => {
    if (selectOptions.length && !selectOptions.find(({ value }) => value === type)) {
      setType(selectOptions[0].value);
    }
  }, [type, selectOptions]);

  const commonProps = {
    opened,
    disabled: disabledInput,
    prepend: typeSelector,
    setOpened
  };

  switch (type) {
    case LOCATION_TYPE:
      return (
        <SelectLocationInput
          invalid={!!(locationName && !locationId)}
          valid={!!(locationName && locationId)}
          value={location}
          onChange={handleLocationChange}
          {...commonProps} />
      );
    case PROJECT_TYPE:
      return (
        <SelectProjectInput
          invalid={!!(projectName && !projectId)}
          valid={!!(projectName && projectId)}
          value={project}
          onChange={handleProjectChange}
          {...commonProps} />
      );
    case TAX_RATE_TYPE:
      return (
        <SelectTaxRateInput
          invalid={!!(taxRate?.name && !taxRate?.id)}
          valid={!!(taxRate?.name && taxRate?.id)}
          value={taxRate}
          onChange={handleTaxRateChange}
          {...commonProps} />
      );
    default:
      return (
        <SelectClassInput
          invalid={!!(className && !classId)}
          valid={!!(className && classId)}
          value={classValue}
          onChange={handleClassChange}
          {...commonProps} />
      );
  }
};

export default React.memo(ExtraDataInput);
