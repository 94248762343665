import Css from "./style.module.scss";

import { Badge } from "nlib/ui";
import Constants from "const/Constants";
import React, { useMemo } from "react";
import Utils from "utils/Utils";
import colorInterpolate from "color-interpolate";

const { PERCENTS_MULTIPLIER } = Constants;

const NEGATIVE = "#e24e45";

const WARNING = "#ffad31";

const POSITIVE = "#12ab6b";

const COLOR_INTENSITY = 0.2;

const BACKGROUND_COLOR = "#fff";

const ConfidenceBadge = ({ confidence, ...restProps }) => {
  const style = useMemo(() => {
    const confidencePercents = Math.floor(confidence * Constants.PERCENTS_MULTIPLIER);

    const color = colorInterpolate([NEGATIVE, WARNING, POSITIVE])(confidencePercents / Constants.PERCENTS_MULTIPLIER);

    return {
      color,
      backgroundColor: colorInterpolate([BACKGROUND_COLOR, color])(COLOR_INTENSITY)
    };
  }, [confidence]);

  return (
    <Badge className={Css.confidenceBadge} style={style} {...restProps}>
      {`${Utils.toMoneyNumber(confidence * PERCENTS_MULTIPLIER)}%`}
    </Badge>
  );
};

export default React.memo(ConfidenceBadge);
