import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ConfidenceBadge from "../ConfidenceBadge";
import React from "react";
import classNames from "classnames";

const ConfidenceAppend = (props) => {
  const {
    className,
    children,
    confidence = 0,
    icon,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  return (
    <div
      className={classNames(Css.confidenceAppend,
        (!!confidence || icon) && Css.showIcon,
        className)}
      {...restProps}>
      {children}
      {icon
        ? (
          <div className={Css.confidence}>{icon}</div>
        )
        : (!!confidence && (
          <div className={Css.confidence}>
            <ConfidenceBadge className={Css.badge} confidence={confidence} data-tooltip={uiTexts.confidence} />
          </div>
        ))}
    </div>
  );
};

export default React.memo(ConfidenceAppend);
