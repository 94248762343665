import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getCurrentQuickBooksRealmId, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import DataConstants from "const/DataConstants";
import MainApiActions from "actions/MainApiActions";
import React, { useCallback, useState } from "react";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";

const {
  DOCUMENT_RECOGNISE_STATUSES,
  STATUSES: { NEED_REACTION, TO_REPORT, EXPORTED, EXCLUDED }
} = DataConstants;

const AttachedDocumentActions = ({ disabled, compact, className, transaction }) => {
  const {
    id: transactionId,
    status,
    documentAttachment,
    documentId,
    documentRecogniseStatus
  } = transaction;

  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const userRole = useSelector(getUserRole);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const { id: selectedBusinessId, settings: { advancedDocumentsWorkflow } = {} } = selectedBusinessData;

  const [loading, setLoading] = useState(false);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const notEdibleStatus = status === TO_REPORT || status === EXCLUDED || (status === EXPORTED && !quickBooksBusiness)
    || documentRecogniseStatus !== DOCUMENT_RECOGNISE_STATUSES.DONE;

  const canEditDocument = !notEdibleStatus && !Utils.checkIsTouchDevice();

  const canDetachDocument = !notEdibleStatus && (!businessUser || status === NEED_REACTION);

  const handleDownloadClick = useCallback(async() => {
    if (documentAttachment) {
      setLoading(true);

      const link = await dispatch(MainApiActions.fetchAttachmentUrl(documentAttachment, true));

      if (link) Utils.downloadContent(link);
      setLoading(false);
    }
  }, [dispatch, documentAttachment]);

  const handleUnpairClick = useCallback(async() => {
    const result = await dispatch(UiActions.showModal(messages.documentDetachConfirm, null, true));

    if (!result) return;
    setLoading(true);
    await dispatch(BusinessesActions.unpairMatches(transactionId, documentId));
    setLoading(false);
  }, [dispatch, documentId, messages, transactionId]);

  const handleEditClick = useCallback(() => {
    window.open(`/${selectedBusinessId}${UiRoutes.DOCUMENTS}?editItem=${documentId}`, "_blank");
  }, [selectedBusinessId, documentId]);

  return (
    <div className={classNames(Css.attachedDocumentActions, compact && Css.compact, className)}>
      {advancedDocumentsWorkflow && canEditDocument
        ? (
          <Button
            large
            className={classNames(Css.button, Css.edit)}
            title={uiTexts.edit}
            disabled={disabled || loading}
            icon={Icons.PencilSimpleLine}
            onClick={handleEditClick}>
            {!compact && uiTexts.edit}
          </Button>
        )
        : (
          !Utils.checkIsTouchDevice() && (
            <Button
              large outline
              className={Css.button}
              title={uiTexts.download}
              disabled={disabled || loading}
              icon={Icons.Download}
              onClick={handleDownloadClick}>
              {!compact && uiTexts.download}
            </Button>
          )
        )}
      {canDetachDocument && (
        <Button
          large
          outline={!compact}
          className={Css.button}
          title={uiTexts.detach}
          danger={compact}
          disabled={disabled || loading}
          icon={Icons.X}
          onClick={handleUnpairClick}>
          {!compact && uiTexts.detach}
        </Button>
      )}
    </div>
  );
};

export default React.memo(AttachedDocumentActions);
