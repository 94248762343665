import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessRpaMode,
  getCurrentQuickBooksRealmId,
  getSelectedBusinessBookCloseDate,
  getSelectedBusinessData,
  getSelectedBusinessIntegrationServiceData
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommentsActions from "actions/CommentsActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import Transactions from "utils/Transactions";
import TransactionsActions from "actions/TransactionsActions";
import Utils from "utils/Utils";
import moment from "moment";
import useShowCommonModal from "./useShowCommonModal";

const {
  COMMENT_TARGET_TYPES: { TRANSACTIONS },
  COMMENT_TYPES: { COMMENT },
  STATUSES: { NEED_REACTION, TO_REPORT, EXPORTED }
} = DataConstants;

const FIELDS = ["reason", "address", "vendorId", "category", "item", "location", "project", "taxRate", "class", "tags"];

const useTransactionUtils = () => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const {
    settings: {
      advancedDocumentsWorkflow,
      allowApproveWithoutPayee
    } = {}
  } = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const selectedBusinessBookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const selectedBusinessIntegrationServiceData = useSelector(getSelectedBusinessIntegrationServiceData);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const showCommonModal = useShowCommonModal();

  const checkIsReadyToReview = useCallback((state, data) => {
    const { reason, category = {} } = state || {};

    const { status, documentId } = data || {};

    return businessUser && status === NEED_REACTION && (documentId || reason.trim() || category.code);
  }, [businessUser]);

  const checkIsReadyToApprove = useCallback((transactionState, transaction) => {
    const { status, documentId } = transaction;

    return !businessUser
        && (!advancedDocumentsWorkflow || !documentId)
        && status !== TO_REPORT
        && status !== EXPORTED
        && Transactions.validateToApprove(transactionState, transaction, allowApproveWithoutPayee);
  }, [advancedDocumentsWorkflow, businessUser, allowApproveWithoutPayee]);

  const editTransaction = useCallback(async(transactionId, transactionState) => {
    const updateData = Utils.getProps(transactionState, FIELDS);

    const { address, vendorId, manualMode } = transactionState;

    const reason = (transactionState.reason || "").trim();

    const result = await dispatch(TransactionsActions.editTransaction(transactionId,
      !businessUser && rpaMode && reason && !manualMode
        ? { reason, address, vendorId }
        : updateData));

    if (reason) {
      dispatch(CommentsActions.fetchComments(TRANSACTIONS, transactionId, false, true)).then(() => {
        dispatch(CommentsActions.addNewComment(COMMENT, `${uiTexts.reason}: ${reason}`, [], true));
      });
    }

    return result;
  }, [dispatch, businessUser, rpaMode, uiTexts.reason]);

  const onTransactionApplyChanges = useCallback((transaction, transactionState) => {
    const { id: transactionId, status, timestamp } = transaction;

    const exportedStatus = status === EXPORTED && !quickBooksBusiness;

    const closedBookTransaction = selectedBusinessBookCloseDate
      && moment.utc(timestamp).isSameOrBefore(moment.utc(selectedBusinessBookCloseDate));

    const warning = !exportedStatus && closedBookTransaction;

    if (warning) {
      const closedBookTimeText = moment.utc(selectedBusinessBookCloseDate).format(Constants.DATETIME_FORMATS.DATE_TEXT_EXT);

      showCommonModal({
        text: Utils.replaceTextVars(messages.closedBookWarning, {
          date: closedBookTimeText,
          service: selectedBusinessIntegrationServiceData.label
        }),
        headerText: uiTexts.warning
      });

      return false;
    }

    return editTransaction(transactionId, transactionState);
  }, [
    editTransaction,
    messages,
    quickBooksBusiness,
    selectedBusinessBookCloseDate,
    selectedBusinessIntegrationServiceData?.label,
    showCommonModal,
    uiTexts
  ]);

  return {
    checkIsReadyToReview,
    checkIsReadyToApprove,
    onTransactionApplyChanges
  };
};

export default useTransactionUtils;
