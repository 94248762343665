import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { AutoTooltip } from "nlib/ui";
import { checkIsBusinessUser } from "selectors/user";
import { getSelectedBusinessCategories, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getTransactionsReasons } from "selectors/transactions";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";

const { STATUSES: { NEED_REACTION, TO_REVIEW } } = DataConstants;

const AdditionalInfo = (props) => {
  const {
    disabled,
    auditRoute,
    usesItems,
    status,
    documentId,
    initialReason,
    category: {
      code: categoryCode = "",
      setByClient: categorySetByClient = false,
      setByRule: categorySetByRule = false,
      confidence: categoryConfidence = 0
    } = {},
    item: {
      setByClient: itemSetByClient = false
    } = {},
    disabledCategorySelect,
    onCategoryChange
  } = props;

  const { uiTexts, messages } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const transactionsReasons = useSelector(getTransactionsReasons);

  const categories = useSelector(getSelectedBusinessCategories);

  const {
    extraData: { integrationService, uncategorizedRpaEnabled, reconciliationRpaEnabled } = {}
  } = useSelector(getSelectedBusinessData);

  const rpaMode = uncategorizedRpaEnabled !== undefined || reconciliationRpaEnabled !== undefined;

  const suggestedCategory = useMemo(() => {
    const suggestedCategoryCode = initialReason && Utils.arrayFind(transactionsReasons, "reason", initialReason, {}).categoryCode;

    return suggestedCategoryCode && Utils.arrayFind(categories, "code", suggestedCategoryCode);
  }, [categories, initialReason, transactionsReasons]);

  const handleCategoryLinkClick = useCallback(() => {
    onCategoryChange(suggestedCategory);
  }, [suggestedCategory, onCategoryChange]);

  if (!auditRoute) {
    if (suggestedCategory && !usesItems && !categoryCode && !businessUser && status === TO_REVIEW && !disabledCategorySelect) {
      const [categorySuggestionTextA, categorySuggestionTextB] = messages.categorySuggestion;

      return (
        <div className={Css.additionalInfo}>
          <AutoTooltip className={Css.description} title={uiTexts.categorizationSuggestion}>
            <Icons.SquaresFour className={Css.highlight} />
            <span>{`${categorySuggestionTextA}: `}</span>
            <a onClick={handleCategoryLinkClick} disabled={disabled}>
              {suggestedCategory && suggestedCategory.displayName}
            </a>
            <span>{`. ${categorySuggestionTextB} `}</span>
            <span>{`«${initialReason}»`}</span>
          </AutoTooltip>
        </div>
      );
    }

    if (initialReason && (status === TO_REVIEW || status === NEED_REACTION)) {
      const aiReason = initialReason.startsWith("AI: ");

      return (
        <div className={Css.additionalInfo}>
          <AutoTooltip className={Css.description} title={aiReason ? uiTexts.aiComment : uiTexts.clientResponse}>
            {aiReason
              ? <Icons.Cpu className={Css.highlight} />
              : <Icons.Chat className={Css.highlight} />}
            <span>{initialReason}</span>
          </AutoTooltip>
        </div>
      );
    }

    if ((usesItems ? itemSetByClient : categorySetByClient) && status === TO_REVIEW) {
      return (
        <div className={Css.additionalInfo} title={uiTexts.categorizedByClient}>
          <AutoTooltip className={Css.description}>
            <Icons.User className={Css.highlight} />
            <span>{uiTexts.categorizedByClient}</span>
          </AutoTooltip>
        </div>
      );
    }

    if (!usesItems && categorySetByRule && status === TO_REVIEW) {
      const integrationServiceName = IntegrationServices.getByValue(integrationService).shortLabel;

      const value = Utils.replaceTextVars(uiTexts.categorizedByServiceRule, { service: integrationServiceName });

      return (
        <div className={Css.additionalInfo}>
          <AutoTooltip className={Css.description} title={value}>
            <Icons.SquaresFour className={Css.highlight} />
            <span>{Utils.replaceTextVars(uiTexts.categorizedByServiceRule, { service: integrationServiceName })}</span>
          </AutoTooltip>
        </div>
      );
    }

    if (documentId && status === TO_REVIEW) {
      return (
        <div className={Css.additionalInfo}>
          <AutoTooltip className={Css.description} title={uiTexts.documentAttached}>
            <Icons.FileText className={Css.highlight} />
            <span>{uiTexts.documentAttached}</span>
          </AutoTooltip>
        </div>
      );
    }

    if (!usesItems && categoryConfidence && status === TO_REVIEW) {
      return (
        <div className={Css.additionalInfo}>
          <AutoTooltip
            className={Css.description}
            title={rpaMode ? uiTexts.categorizedByRoboticAi : uiTexts.categorizedAutomatically}>
            <Icons.Cpu className={Css.highlight} />
            <span>{rpaMode ? uiTexts.categorizedByRoboticAi : uiTexts.categorizedAutomatically}</span>
          </AutoTooltip>
        </div>
      );
    }
  }

  return null;
};

export default React.memo(AdditionalInfo);
