import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "nlib/ui";
import { checkIsBusinessUser } from "selectors/user";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { getOpenedCommentsEntityId } from "selectors/ui";
import { useDispatch, useSelector } from "react-redux";
import CommentsBlock from "nlib/common/CommentsBlock";
import DataConstants from "const/DataConstants";
import DropDown from "nlib/ui/DropDown";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import React, { useCallback } from "react";
import UiActions from "actions/UiActions";
import classNames from "classnames";

const {
  COMMENT_TARGET_TYPES: { TRANSACTIONS, AUDIT },
  STATUSES: { EXPORTED }
} = DataConstants;

const Comments = (props) => {
  const {
    audit,
    status,
    disabled,
    transactionId,
    comments,
    comments: {
      all: allCommentsCount = 0,
      unread: unreadCommentsCount = 0
    } = {},
    onAskClient
  } = props;

  const dispatch = useDispatch();

  const businessUser = useSelector(checkIsBusinessUser);

  const dropdownOpened = useSelector(getOpenedCommentsEntityId) === transactionId;

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const disableAskClient = businessUser || (!quickBooksBusiness && status === EXPORTED);

  const handleCloseClick = useCallback(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(null));
  }, [dispatch]);

  const handleShowMoreClick = useCallback(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(transactionId));
  }, [dispatch, transactionId]);

  const handleAskClient = useCallback(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(null));
    onAskClient();
  }, [dispatch, onAskClient]);

  return (
    <div className={Css.comments}>
      <DropDown opened={dropdownOpened}>
        <div
          disabled={disabled}
          className={Css.button}
          onClick={handleShowMoreClick}>
          <Icons.ChatText weight={dropdownOpened ? "fill" : "regular"} />
          <Badge
            title={allCommentsCount}
            attention={!!unreadCommentsCount}
            className={Css.badge}
            value={allCommentsCount} />
        </div>
        <DropDownContent portal className={classNames(Css.dropdownContent, businessUser && Css.businessUser)}>
          <CommentsBlock
            popup
            className={Css.commentsBlock}
            compact={businessUser}
            commentTarget={audit ? AUDIT : TRANSACTIONS}
            commentTargetId={transactionId}
            comments={comments}
            onClose={handleCloseClick}
            onAskClient={disableAskClient ? null : handleAskClient} />
        </DropDownContent>
      </DropDown>
    </div>
  );
};

export default React.memo(Comments);
