import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge, DropDown } from "nlib/ui";
import { DropDownContent } from "nlib/ui/DropDown";
import {
  getContactsData,
  getVendorsCategories,
  getVendorsClasses,
  getVendorsLocations,
  getVendorsProjects,
  getVendorsTaxRates
} from "selectors/contacts";
import {
  getSelectedBusinessCategories,
  getSelectedBusinessClasses,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import ConfidenceBadge from "../ConfidenceBadge";
import Constants from "const/Constants";
import ContactsActions from "actions/ContactsActions";
import React, { useCallback, useMemo, useState } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";

const MAX_VALIDATION_ITEMS_TO_SHOW = 5;

const LOW_LEVEL = 10;

const MEDIUM_LEVEL = 50;

const IconWarning = () => {
  return (
    <Badge className={Css.badge} theme="warning">
      <Icons.Warning />
    </Badge>
  );
};

const IconCriticalWarning = () => {
  return (
    <Badge className={Css.badge} theme="negative">
      <Icons.Warning />
    </Badge>
  );
};

const Link = (props) => {
  const { title, value, current, frequency, critical, onClick } = props;

  const { uiTexts } = useSelector(getTextsData);

  const clickable = !!title && !current && !!onClick;

  const labelClassName = useMemo(() => {
    if (!title) return Css.mediumDarkText;
    if (+frequency < LOW_LEVEL) return Css.negativeText;
    if (+frequency < MEDIUM_LEVEL) return Css.warningText;

    return Css.positiveText;
  }, [frequency, title]);

  const handleClick = useCallback(() => onClick(value), [onClick, value]);

  return (
    <div
      className={classNames(
        Css.link,
        clickable && Css.clickable,
        current && Css.warningText,
        critical && Css.critical
      )}
      title={title}
      onClick={clickable ? handleClick : undefined}>
      <span className={classNames(Css.label, labelClassName)} />
      <span className={Css.text}>{title || uiTexts.unknown}</span>
      {frequency !== null && (
        <Badge className={Css.frequency}>{` ${frequency || "<1"}%`}</Badge>
      )}
    </div>
  );
};

const Links = (props) => {
  const {
    idProp,
    nameProp,
    entityId,
    entitiesList,
    idsList,
    moreCount,
    onLinkClick
  } = props;

  const currentIndex = idsList?.length ? idsList.findIndex(([id]) => id === entityId) : -1;

  const listItems = moreCount
    ? idsList.filter(([id], index) => {
      return currentIndex < MAX_VALIDATION_ITEMS_TO_SHOW
        ? index < MAX_VALIDATION_ITEMS_TO_SHOW
        : entityId === id || (index < MAX_VALIDATION_ITEMS_TO_SHOW - 1);
    })
    : idsList;

  return listItems.map(([id, , usageFrequency]) => {
    return (
      <Link
        key={id}
        title={Utils.arrayFind(entitiesList, idProp, id)?.[nameProp]}
        value={id}
        current={id === entityId}
        frequency={Math.round(usageFrequency * Constants.PERCENTS_MULTIPLIER)}
        onClick={onLinkClick} />
    );
  });
};

const ENTITIES_SELECTORS = {
  categories: getSelectedBusinessCategories,
  classes: getSelectedBusinessClasses,
  locations: getSelectedBusinessLocations,
  projects: getSelectedBusinessProjects,
  taxRates: getSelectedBusinessTaxRates
};

const VENDORS_ENTITIES_SELECTORS = {
  categories: getVendorsCategories,
  classes: getVendorsClasses,
  locations: getVendorsLocations,
  projects: getVendorsProjects,
  taxRates: getVendorsTaxRates
};

const TEXT_TEMPLATE_LABELS = {
  categories: "vendorsCategoriesValidationWarning",
  classes: "vendorsClassesValidationWarning",
  locations: "vendorsLocationsValidationWarning",
  projects: "vendorsProjectsValidationWarning",
  taxRates: "vendorsTaxRatesValidationWarning"
};

const VendorValidation = (props) => {
  const [openedState, setOpenedState] = useState(false);

  const {
    skip,
    opened = openedState,
    children,
    vendorId,
    listName,
    name,
    idProp = "id",
    nameProp = "name",
    entityValue: { [idProp]: entityId, [nameProp]: entityName },
    confidence,
    setOpened = setOpenedState,
    updateTransaction
  } = props;

  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const contactsData = useSelector(getContactsData);

  const contactData = Utils.arrayFindById(contactsData, vendorId);

  const entitiesList = useSelector(ENTITIES_SELECTORS[listName]);

  const vendorEntities = useSelector(VENDORS_ENTITIES_SELECTORS[listName])?.[vendorId];

  const idsProp = name === "category" ? "categoryCodes" : `${name}Ids`;

  const {
    showWarning,
    showCriticalWarning,
    moreCount,
    idsList
  } = useMemo(() => {
    if (skip) return {};

    const mostUsedIdProp = name === "category" ? "mostUsedCode" : "mostUsedId";

    const lastUsedIdProp = name === "category" ? "lastUsedCode" : "lastUsedId";

    const {
      [idsProp]: ids,
      [mostUsedIdProp]: mostUsedId,
      [lastUsedIdProp]: lastUsedId
    } = vendorEntities || {};

    const show = !!ids?.length && entityId && entityId !== mostUsedId && entityId !== lastUsedId;

    return {
      idsList: ids,
      showWarning: show,
      showCriticalWarning: show && ids.every(([id]) => id !== entityId),
      moreCount: show && ids?.length && Math.max(ids?.length - MAX_VALIDATION_ITEMS_TO_SHOW, 0)
    };
  }, [entityId, idsProp, name, skip, vendorEntities]);

  const capitalizedName = useMemo(() => Utils.capitalizeText(contactData?.name), [contactData?.name]);

  const textTemplateLabel = TEXT_TEMPLATE_LABELS[listName];

  const handleLinkClick = useCallback((value) => {
    const newValue = Utils.arrayFind(entitiesList, idProp, value);

    updateTransaction(newValue);
  }, [entitiesList, idProp, updateTransaction]);

  const handleStopValidationClick = useCallback(async() => {
    const text = Utils.replaceTextVars(messages.stopVendorValidation, { contactName: contactData?.name });

    const result = await dispatch(UiActions.showModal(text, null, true));

    if (result) dispatch(ContactsActions.stopVendorsValidation(vendorId));
  }, [messages.stopVendorValidation, contactData?.name, dispatch, vendorId]);

  const [
    vendorValidationWarningA,
    vendorValidationWarningB,
    vendorValidationWarningC
  ] = messages[textTemplateLabel];

  const handleMouseEnter = useCallback(() => {
    setOpened(true);
  }, [setOpened]);

  const handleMouseLeave = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const iconComponent = useMemo(() => {
    if (!showCriticalWarning && !showWarning) return undefined;

    return (
      <div
        className={Css.icon}
        onMouseEnter={handleMouseEnter}>
        {showCriticalWarning ? <IconCriticalWarning /> : <IconWarning />}
      </div>
    );
  }, [showCriticalWarning, showWarning, handleMouseEnter]);

  return (
    <DropDown
      opened={opened}
      onMouseLeave={handleMouseLeave}
      setOpened={setOpened}>
      {children && children({ showWarning, showCriticalWarning, iconComponent })}
      {showWarning && (
        <DropDownContent alignRight className={Css.dropDown}>
          <div className={classNames(Css.vendorValidation, props.className)}>
            {!!confidence && (
              <div className={Css.confidence}>
                <div>{uiTexts.confidence}</div>
                <ConfidenceBadge className={Css.badge} confidence={confidence} />
              </div>
            )}
            <div className={Css.scroll}>
              <div className={Css.block}>
                <div className={Css.title}>
                  {`${Utils.replaceTextVars(vendorValidationWarningA, { contactName: capitalizedName })}: `}
                </div>
                <div className={Css.links}>
                  <Links
                    idProp={idProp}
                    nameProp={nameProp}
                    entityId={entityId}
                    entityName={entityName}
                    entitiesList={entitiesList}
                    idsList={idsList}
                    vendorId={vendorId}
                    name={name}
                    moreCount={moreCount}
                    onLinkClick={handleLinkClick} />
                  {(!!moreCount || showCriticalWarning) && (
                    <span className={Css.text}>
                      {!!moreCount && (
                        <span>{Utils.replaceTextVars(vendorValidationWarningB, { moreCount }).toLocaleLowerCase()}</span>
                      )}
                      {!!moreCount && showCriticalWarning && <span>, </span>}
                      {showCriticalWarning && (
                        <span>{vendorValidationWarningC.toLocaleLowerCase()}</span>
                      )}
                    </span>
                  )}
                  {showCriticalWarning && (
                    <Link critical title={entityName} frequency={null} />
                  )}
                </div>
              </div>
            </div>
            <div className={Css.description}>
              {messages.vendorsValidationSummary}
            </div>
            <div className={Css.ignoreValidation} onClick={handleStopValidationClick}>
              <Icons.MinusCircle className={Css.negativeText} />
              <span>{uiTexts.ignoreValidation}</span>
            </div>
          </div>
        </DropDownContent>
      )}
    </DropDown>
  );
};

export default React.memo(VendorValidation);
