import { getSelectedBusinessData } from "selectors/businesses";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import DataConstants from "const/DataConstants";
import DocumentsActions from "actions/DocumentsActions";

const {
  DOCUMENT_PAYMENT_TYPES: { BUY, SELL },
  TRANSACTION_TYPES: { WITHDRAW }
} = DataConstants;

const useAttachDocument = ({ id: transactionId, documentId, type }) => {
  const dispatch = useDispatch();

  const {
    settings: { advancedDocumentsWorkflow } = {}
  } = useSelector(getSelectedBusinessData);

  return useCallback(async(file) => {
    if (documentId) await dispatch(BusinessesActions.unpairMatches(transactionId, documentId, false, true));

    const result = await dispatch(DocumentsActions.uploadDocuments([file],
      { transactionAttachment: !advancedDocumentsWorkflow, paymentType: type === WITHDRAW ? BUY : SELL }));

    if (result) {
      const [newDocument] = result;

      if (newDocument) {
        await dispatch(BusinessesActions.pairMatches(transactionId, newDocument.id, false, true));

        return newDocument;
      }
    }

    return false;
  }, [advancedDocumentsWorkflow, dispatch, documentId, transactionId, type]);
};

export default useAttachDocument;
