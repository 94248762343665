import Css from "./style.module.scss";

import { getCurrentZohoOrganizationId, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ConfidenceAppend from "nlib/common/ConfidenceAppend";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";
import SelectContactInput from "nlib/common/SelectContactInput";
import classNames from "classnames";

const { TRANSACTION_TYPES: { WITHDRAW } } = DataConstants;

const { AUDIT_SUBSECTIONS_ROUTES } = Constants;

const SelectContact = (props) => {
  const {
    auditRoute,
    className,
    vendorId,
    address,
    disabled,
    documentId,
    placeholder,
    transaction,
    onChange
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const {
    settings: { advancedDocumentsWorkflow, allowApproveWithoutPayee } = {}
  } = useSelector(getSelectedBusinessData);

  const { usesItems, type } = transaction;

  const withdrawTransaction = type === WITHDRAW;

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const nameNotChanged = address?.name === (transaction?.address?.name || "");

  return (
    <div className={classNames(Css.contact, className)}>
      <ConfidenceAppend
        className={Css.confidenceAppend}
        confidence={address?.confidence}>
        <SelectContactInput
          useWidget
          value={address}
          disabled={disabled}
          placeholder={!placeholder && !address?.name && disabled ? uiTexts.noContact : placeholder}
          onlyVendors={withdrawTransaction && zohoBusiness}
          onlyCustomers={!!usesItems || (!withdrawTransaction && zohoBusiness)}
          probablyVendor={withdrawTransaction && !usesItems}
          active={(!auditRoute || !nameNotChanged) && !!(address?.name && !vendorId)}
          invalid={auditRoute
            ? ((!nameNotChanged || (auditRoute === AUDIT_SUBSECTIONS_ROUTES.NO_PAYEE)) && !vendorId)
            : (!vendorId && (!documentId || !advancedDocumentsWorkflow) && !allowApproveWithoutPayee)}
          valid={!!(address?.name && (vendorId || (auditRoute && nameNotChanged)))}
          onChange={onChange} />
      </ConfidenceAppend>
    </div>
  );
};

export default React.memo(SelectContact);
