import Css from "./style.module.scss";

import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo, useState } from "react";
import ReasonInput from "nlib/common/ReasonInput";
import Select from "nlib/ui/Select";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import UserRoles from "const/UserRoles";
import classNames from "classnames";

const REASON_TYPE = "reasonType";

const CATEGORY_TYPE = "categoryType";

const { STATUSES: { NEED_REACTION } } = DataConstants;

const CategoryAndReasonInput = (props) => {
  const {
    validate,
    disabled,
    readOnly,
    className,
    transactionState,
    transaction,
    localReasons,
    onChange,
    onReasonBlur
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const userRole = useSelector(getUserRole);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const [opened, setOpened] = useState(false);

  const {
    settings: { allowClientCategorySelection } = {}
  } = selectedBusinessData;

  const { reason: originalReason, usesItems, status, type } = transaction || {};

  const {
    id,
    reason,
    category = {},
    directCategorySelection = !businessUser
  } = transactionState;

  const selectOptions = useMemo(() => [
    businessUser && { value: REASON_TYPE, label: uiTexts.reason },
    (!businessUser || allowClientCategorySelection) && !usesItems && { value: CATEGORY_TYPE, label: uiTexts.category }
  ].filter(Boolean), [businessUser, allowClientCategorySelection, usesItems, uiTexts]);

  const handleTypeChange = useCallback((value) => {
    const newValue = value === CATEGORY_TYPE;

    onChange(id, {
      directCategorySelection: newValue,
      reason: newValue ? "" : reason,
      category: newValue ? category : {}
    });
  }, [category, id, reason, onChange]);

  const handleReasonChange = useCallback((value) => {
    onChange(id, { reason: value });
  }, [id, onChange]);

  const handleCategoryChange = useCallback((value) => {
    onChange(id, { category: value, tags: value.code ? [value.name] : [] });
  }, [id, onChange]);

  const handleSelectClick = useCallback(() => {
    setOpened(false);
  }, []);

  const typeSelector = useMemo(() => {
    if (readOnly || selectOptions.length <= 1) {
      return null;
    }

    return (
      <Select
        className={Css.typeSelect}
        dropDownClassName={Css.typeSelectDropdownClassName}
        value={directCategorySelection ? CATEGORY_TYPE : REASON_TYPE}
        options={selectOptions}
        onChange={handleTypeChange}
        onClick={handleSelectClick} />
    );
  }, [readOnly, directCategorySelection, selectOptions, handleTypeChange, handleSelectClick]);

  if (selectOptions.length < 1) return null;

  return (
    <div className={classNames(Css.categoryAndReasonInput, className, !!typeSelector && Css.typeSelector)}>
      {directCategorySelection
        ? (
          <SelectCategoryInput
            disabled={disabled}
            readOnly={readOnly}
            opened={opened}
            className={Css.input}
            entityPaymentType={type}
            prepend={typeSelector}
            value={category}
            valid={validate && !!category.code}
            invalid={validate && !category.code}
            setOpened={setOpened}
            onChange={handleCategoryChange} />
        )
        : (
          <ReasonInput
            validate={validate}
            disabled={disabled}
            readOnly={readOnly}
            status={status}
            opened={opened}
            className={Css.input}
            prepend={typeSelector}
            originalReason={originalReason}
            localReasons={localReasons}
            value={(businessUser && status === NEED_REACTION ? reason : originalReason) || ""}
            onChange={handleReasonChange}
            onBlur={onReasonBlur}
            setOpened={setOpened} />
        )}
    </div>
  );
};

export default React.memo(CategoryAndReasonInput);
