import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoTooltip from "nlib/ui/AutoTooltip";
import React from "react";
import TransactionAmount from "nlib/common/TransactionAmount";
import Utils from "utils/Utils";

const AmountCell = ({ transaction }) => {
  const { type, currency, amount, amountTotal } = transaction;

  const { uiTexts } = useSelector(getTextsData);

  return (
    <div className={Css.amountCell}>
      <div className={Css.split}>
        {!!amountTotal && uiTexts.split}
      </div>
      <div className={Css.amount}>
        <AutoTooltip>
          <TransactionAmount
            amount={amount}
            type={type}
            currency={currency} />
        </AutoTooltip>
      </div>
      {!!amountTotal && (
        <div className={Css.amountTotal}>
          <AutoTooltip>
            <span>{uiTexts.total}: </span>
            <span className={Css.total}>{Utils.toMoneyString(amountTotal, currency)}</span>
          </AutoTooltip>
        </div>
      )}
    </div>
  );
};

export default React.memo(AmountCell);
